'use client';

import Providers from 'shared/providers';
import { CssBaseline } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GoogleTagManager } from '@next/third-parties/google';
import { setupZendesk } from 'shared/providers/zendesk/zendesk-script';
import { usePathname } from 'next/navigation';
import Script from 'next/script';

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  const { t } = useTranslation();
  const pathname = usePathname();

  return (
    <html lang={process.env.NEXT_PUBLIC_COUNTRY || 'en'}>
      <head>
        <title>{t('metadata.home.title')}</title>
        <meta name={'description'} content={t('metadata.home.description')} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <link href="/assets/fonts/odds.css" rel="stylesheet" />
        {process.env.LOCALE !== 'ru' && (
          <Script
            id="ze-snippet"
            strategy="afterInteractive"
            src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`}
            onLoad={() => setupZendesk(pathname)}
          />
        )}
        {process.env.LOCALE === 'ro' && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `
            maestra = window.maestra || function() { maestra.queue.push(arguments); };
            maestra.queue = maestra.queue || [];
            maestra('create', {
                endpointId: 'mokka-eu.Website'
            });

           `,
              }}
            />
            <script src="https://api.maestra.io/scripts/v1/tracker.js" async />
          </>
        )}

        {process.env.LOCALE === 'ru' && (
          <>
            <Script id="mindbox-init" strategy="afterInteractive">
              {`
        mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
        mindbox.queue = mindbox.queue || []; 
        mindbox('create');
      `}
            </Script>
            <Script
              src="https://api.mindbox.ru/scripts/v1/tracker.js"
              strategy="afterInteractive"
            />
          </>
        )}
      </head>
      <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID || ''} />
      <body>
        <CssBaseline />
        <Providers>{children}</Providers>
      </body>
    </html>
  );
}
